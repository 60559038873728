import React, { useState } from "react";
import SuccessToast from "./types/SuccessToast";
import ErrorToast from "./types/ErrorToast";

function Toast({ toast, setToast }) {
  const { type, message } = toast;
  const [visible, setVisible] = useState(true);

  const handleToast = () => {
    setVisible(false);
    setTimeout(() => {
      setToast(null);
    }, 500);
  };

  if (type === "error") {
    return (
      <ErrorToast
        visible={visible}
        handleToast={handleToast}
        message={message}
      />
    );
  }

  return (
    <SuccessToast
      visible={visible}
      handleToast={handleToast}
      message={message}
    />
  );
}

export default Toast;
