export const validate = values => {
          
        const errors = {};
     
        if (!values.email) {
            errors.email = 'Email address is required.';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        } 
        if (!values.password) {
            errors.password = 'Password cannot be empty.';
        } else if (values.password.toString().length < 8) {
            errors.password = 'Password cannot be less than 8 characters.';
        }

        if (!values.password_confirmation) {
            errors.password_confirmation = 'Password confirmation is required.';
        }

        if (values.password_confirmation.toString() !== values.password.toString() ) {
            errors.password_confirmation = 'Password mismatch.';
        }                    

        if (!values.first_name) {
            errors.first_name = 'First name is required.';
        }
        if (!values.og_name) {
            errors.og_name = 'Organisation name is required.';
        }        
        if (!values.last_name) {
            errors.last_name = 'Last name is required.';
        }
        if (!values.state) {
            errors.state = 'State field is required.';
        }
        if (!values.country) {
        errors.country = 'Country field is required.';
        }
        if (!values.phone_number) {
            errors.phone_number = 'Business Contact is required.';
        }
      return errors;
   }
