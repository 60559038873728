import React, { useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';
import { editorDefault } from '../components/editor-default';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../../../api';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import ScaleLoader_ from '../../../components/Loader/ScaleLoader'

const EditNewsletter = () => {

  const emailEditorRef = useRef(null);
  const editorContent = JSON.parse(sessionStorage.getItem('active-editor-content'))
  const [emailSubject, setEmailSubject ] = useState(editorContent.subject)

  const navigate = useNavigate()
  const navigateBack = () => navigate(-1)

  const updateNewsletterMutation = useMutation( api.updateNewsletters, {
    onSuccess : (data) => {
       toast.success('newsletter updated successfully!')
       navigateBack()
    }
  })

  const exportHtml = (status) => {
     if(emailSubject === ''){
        toast.error(`Error! newsletter subject can't be empty`)
     }
     else{
      const unlayer = emailEditorRef.current?.editor;
      unlayer?.exportHtml((data) => {
        const { design, html } = data;
        sessionStorage.setItem('editor_html', html)
      });
  
      unlayer.saveDesign( design => {
        sessionStorage.setItem('editor_json', JSON.stringify(design))
      })
  
      setTimeout( () => {
          updateNewsletterMutation.mutate({
             id: editorContent.id,
             data: {
              status,
              subject: emailSubject,
              content: sessionStorage.getItem('editor_html'),
              json_content: sessionStorage.getItem('editor_json')
             }
          })
      }, 100 )
     }
  };

  const onReady = (unlayer) => {
    // Editor is ready
    // You can load your template here;
    // The design json can be obtained by calling
    // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)

    // const templateJson = { DESIGN JSON GOES HERE };
    // unlayer.loadDesign(templateJson);
  };

  const loadTemplate = (editor) => {
    const unlayer = emailEditorRef.current?.editor;
     unlayer.loadDesign(JSON.parse(editorContent.json_content))
  }

  return (
    <div className='pt-4'> {updateNewsletterMutation.isLoading && <ScaleLoader_ />}
      <div className='flex justify-between px-2 mb-5'>
         <h5 className='text-xl'> Create Newsletter</h5>
         <button 
            onClick={navigateBack}
             className='text-md bi bi-arrow-left px-12 rounded-lg py-2 border border-gray-200'>
               &nbsp; Back
         </button>
        {/*<button className='py-3 px-5 bg-gray-500' onClick={exportHtml}>Export HTML</button>*/}
      </div>
      <input
        type="text"
        className="relative m-0 -mr-0.5 block w-full min-w-0  rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 mb-4 py-4 text-lg font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-green-500 focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,02)] focus:outline-none"
        placeholder="Email Template Subject..."         
        aria-describedby="button-addon1" 
        autoFocus={true}
        value={emailSubject}
        onChange={ (e) => setEmailSubject(e.target.value) }
      />
      <EmailEditor  ref={emailEditorRef} onLoad={loadTemplate} onReady={onReady} />
      <div className='mt-5 flex md:justify-end md:pe-5'>
          <button onClick={() => exportHtml('draft')} className='bg-gray-200 rounded-lg px-12 py-3 text-xl mr-8'>
              &nbsp; Save Draft
          </button>
          <button  onClick={() => exportHtml('published')} className='bg-armyGreen bi bi-send text-white rounded-lg px-12 py-3 text-xl'>
              &nbsp;Send Edited
          </button>           
      </div>
    </div>
  );
};

 export default EditNewsletter;
