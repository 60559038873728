import React, {useEffect, useState} from 'react'
import toast from 'react-hot-toast';
import { Ripple, initTE } from "tw-elements";

const SearchTemplate = ({getNewsletterMutation}) => {
    
    const [search, setSearch] = useState('')

    useEffect( () => initTE({ Ripple }) , [] )

    const searchNewsletter = () => {
         if(search === '') toast.error(`Sorry! search field can't be empty`)
         else getNewsletterMutation.mutate(`&search=${search}`)
    }

  return (
    <div className="mb-3">
    <div className="relative mb-4 flex w-full flex-wrap items-stretch">
        <input
        type="search"
        className="relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-green-500 focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,02)] focus:outline-none"
        placeholder="Search Email Template..."
        aria-label="Search"
        value={search}
        onChange={ event => setSearch(event.target.value ) }
        aria-describedby="button-addon1" />
        
        <button
        className="relative z-[2] flex items-center rounded-r bg-customGreen px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-customGreen hover:shadow-lg focus:bg-customGreen focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-500 active:shadow-lg"
        type="button"
        id="button-addon1"
        onClick={searchNewsletter}
        data-te-ripple-init
        data-te-ripple-color="light">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="h-5 w-5">
            <path
            fill-rule="evenodd"
            d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
            clip-rule="evenodd" />
        </svg>
        </button>
    </div>
    </div>
  )
}

export default SearchTemplate