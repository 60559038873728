import React, { useState } from "react";
import { api } from "../../../../../api/download";
import { useQuery } from "react-query";
import useWindowDimensions from "../../../../../hooks/useWindowSize";

import Filter from "../../../components/Filter/Filter";
import FileSaver from "file-saver";
import CryptoJS from "crypto-js";
import URL from "../../../../../url";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useCustomToast } from "../../../../../components/customtoast/CustomToastProvider";

const Booking = () => {
  const customToast = useCustomToast();
  const [examID, setExamID] = useState("");
  const { width } = useWindowDimensions();
  const encryptedToken = sessionStorage.getItem("userToken");
  const exportUrl = `${URL}/admin/export-data/booking?exam_id=${examID}`;
  const key = process.env.REACT_APP_SECRET_KEY;

  const { data: exams } = useQuery(["exams"], async () => {
    const { data } = await api.getExams();
    return data;
  });

  const handleDownload = () => {
    toast.loading("Fetching");

    const decryptedToken = CryptoJS.AES.decrypt(encryptedToken, key);
    const userToken = decryptedToken.toString(CryptoJS.enc.Utf8);

    const config = {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${userToken}`, // Replace `yourBearerToken` with the actual bearer token value
      },
    };

    axios
      .get(exportUrl, config)
      .then((response) => {
        const dataTitle = "booking";
        toast.remove();
        customToast.success("Data fetched!");
        FileSaver.saveAs(response.data, dataTitle);
      })
      .catch(({ response }) => {
        toast.remove();
        if (response?.status === 403) {
          customToast.error("You don't have permission to perform this action");
        } else {
          customToast.error("Could not download XLSX");
        }
      });
  };

  return (
    <div>
      <h2 className="font-semibold text-base">Bookings</h2>
      <div
        className={`w-full flex ${
          width > 1100 ? "items-center" : "items-start"
        } justify-between ${width > 1100 ? "flex-row" : "flex-col"}`}
      >
        <div
          className={`py-9 flex ${
            width > 935 ? "items-center" : "items-start"
          } justify-start gap-4 ${width > 935 ? "flex-row" : "flex-col"}`}
        >
          <Filter
            options={exams}
            optionValueKey={"id"}
            optionTextKey={"title"}
            setFilter={setExamID}
            placeholder={"Exam"}
          />
          <button
            onClick={handleDownload}
            className="border border-primary py-1 px-4 text-primary rounded"
          >
            Download
          </button>
        </div>
      </div>

      <hr className="py-4" />
    </div>
  );
};

export default Booking;
