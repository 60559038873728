import createApiClient from "./api";
// Create an Axios instance
const client = createApiClient();

export const api = {
  getUsers: ({ search, isDeleted }) =>
    client
      .get(`v2/admin/users?search=${search}&is_deleted=${isDeleted}`)
      .then(({ data }) => data),

  getEmailVerificationSummary: () =>
    client
      .get(`v2/admin/users/email-verifications/summary`)
      .then(({ data }) => data),

  getEmailVerificationData: () =>
    client.get(`v2/admin/users/email-verifications`).then(({ data }) => data),

  verifyAccount: ({ payload }) =>
    client.post(`v2/admin/users/verify-email`, payload).then(({ data }) => data),

  deleteAccount: ({ id }) =>
    client.patch(`v2/admin/users/${id}/delete-account`).then(({ data }) => data),

  suspendAccount: ({ id }) =>
    client.patch(`v2/admin/users/${id}/update-status`).then(({ data }) => data),
};
