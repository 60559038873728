import { useContext } from "react";
import classes from "./AdminSidebar.module.css";
import SidebarItem from "../SidebarItem/SidebarItem";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../api";
import toast from "react-hot-toast";

const AdminSidebar = ({
  pages,
  setPage,
  currentPage,
  isOpen,
  setIsOpen,
  windowWidth,
  refreshTime,
}) => {
  const handleItemSelect = (id) => {
    setPage(id);

    if (windowWidth < 768) {
      setIsOpen(false);
    }
  };
  const location = useLocation();
  const { value } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    toast.loading("Logging you out...");
    try {
      const data = await api.logout();
      toast.remove();
      toast.success(data?.message);
      navigate("/");
      sessionStorage.clear();
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  return (
    <div
      className={`${classes.Sidebar} ${
        isOpen ? classes.OpenSidebar : classes.CloseSidebar
      } fixed top-[60px]  md:top-0 md:sticky flex-1 bg-white z-10 admin-sidebar overflow-y-auto`}
    >
      <div>
        <div className="mb-3 p-5">
          {/* <h3>Dashboard</h3> */}
          <ul>
            {pages?.map((page) => (
              <SidebarItem
                key={page.title}
                label={page.title}
                icon={page.icon}
                path={page.path}
                clicked={() => handleItemSelect(page.id)}
                active={location.pathname.includes(page.path) ? true : false}
              />
            ))}
          </ul>
          <div className="mb-4 mt-16 border-t-2 border-gray-200">
            <button
              onClick={handleLogout}
              className="border mt-5 bg-customOrange block w-full px-8 py-3 rounded text-white capitalize text-sm"
            >
              log out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
