import React from "react";
import VerticalMenu from "../../../../components/global/VerticalMenu";
import { useNavigate } from "react-router-dom";
import Table from "./Table";
import Loader from "../Loader/Loader";

function RequestTable({
  data,
  keys,
  rowOptions,
  titleNavLink,
  dataBody,
  btnTitle,
  onTitleClick,
}) {
  const navigate = useNavigate();
  return (
    <>
      {data?.map((item, index) => (
        <tr key={item.id}>
          <td>
            <p>
              {dataBody
                ? (dataBody?.current_page - 1) * dataBody?.per_page +
                  (index + 1)
                : index + 1}
            </p>
          </td>
          {titleNavLink && (
            <td>
              <button
                className="text-customGreen hover:underline"
                onClick={() => {
                  navigate(item["link"]);
                  if(onTitleClick !== null) onTitleClick(item) 
                }}
              >
                {item["title"] ? item["title"] : item["name"]}
              </button>
            </td>
          )}
          {titleNavLink
            ? keys
                .filter(
                  (key) =>
                    key && key !== "name" && key !== "title" && key !== "link"
                )
                .map((key, i) => (
                  <td key={i}>
                    {typeof item[key] === "object"
                      ? item[key]?.title
                      : item[key] === "object"
                      ? item[key]?.name
                      : item[key]}
                  </td>
                ))
            : keys
                .filter((key) => key && key !== "link")
                .map((key, i) => (
                  <td key={i}>
                    {typeof item[key] === "object"
                      ? item[key]?.title
                      : item[key] === "object"
                      ? item[key]?.name
                      : item[key]}
                  </td>
                ))}
          {rowOptions && (
            <td>
              <VerticalMenu isBackground>
                <div>
                  {rowOptions?.map((option, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        option?.action(item);
                      }}
                      className="block w-full border border-slate-100 px-4 py-2 text-left bg-white duration-200 text-baseFont text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                    >
                      {option?.name}
                    </button>
                  ))}
                </div>
              </VerticalMenu>
            </td>
          )}
          {item["action"] && (
            <td>
              <button
                onClick={() => {
                  item["action"](item);
                }}
                className="px-5 py-2 border border-customGreen duration-200 hover:bg-white rounded-md text-sm text-customGreen"
              >
                {btnTitle}
              </button>
            </td>
          )}
        </tr>
      ))}
    </>
  );
}

export function UpdatedRequestTable({ columns, data, isLoading }) {
  if (isLoading) {
    return <Loader />;
  }

  return (
    <Table>
      <thead>
        <tr>
          {columns.map((header) => (
            <th key={header.key}>{header.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.map((row, index) => (
          <tr key={index}>
            {columns.map((column) => {
              const item = row[column.selector];
              return (
                <td key={column.key}>
                  {column.render ? column.render({ item, row, index }) : item}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default RequestTable;
