import React from "react";
import { checkPermission } from "../check-permission";
import UnAuthorized from "../UnAuthorized";

function ProtectedRoute({ permission, children }) {
  const user = JSON.parse(sessionStorage.getItem("user"));

  if (!checkPermission(user?.permissions, permission)) {
    return <UnAuthorized />;
  }
  return <div>{children}</div>;
}

export default ProtectedRoute;
