import React from "react";
import TestAssessify from "../../../../images/TestAssessify.png";
import "./styles.css";

const Fallback = () => {
  return (
    <div
      style={{ height: "93vh" }}
      className="w-full flex items-center justify-center grayscale-animation"
    >
      <img className="w-35 h-20" src={TestAssessify} />
    </div>
  );
};

export default Fallback;
