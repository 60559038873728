export const URL = {
  Exams: "exams",
  Exams_Attendance: "attendance",
  Exams_Bookings: ":id/bookings",
  Create_Exam: "create-exam",
  View_Exam: ":id",
  Pipelines: "pipelines",
  create_campaign: "create-campaign",
  affiliate_dashboard: "dashboard",
  view_campaigns: "campaigns/",
  edit_campaign: "edit-campaign",
  Assessments: "assessments",
  Create_Assessment: "create-assessment",
  Edit_Assessment: ":id",
  QuestionBank: "question-bank",
  Create_Question: "create-question",
  Flagged_Questions: "flagged-questions",
  Edit_Question: "edit-question",
  affiliate_campaign_links: "view-campaign-links",
  view_affiliates: "view-affiliates/",
  Add_Participant: "add-participant",
  Exam_Monitor: "exam-monitor",
  Dashboard: "dashboard",
  view_campaign_applications: "applications/",
  view_campaign_referrals: "referrals",
  view_link_activity: "view_link_activity",
  audit_trail: "audit_trail",
  selection: "selection",
  applications: "applications",
  onlineTest: "online-test",
  physicalTest: "physical-test",
};
