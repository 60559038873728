import {
  AccountBoxRounded,
  AccountBalanceWalletRounded,
  AssessmentRounded,
  SettingsApplicationsRounded,
  AccountBalance,
  PeopleAltRounded,
  ForumRounded,
  BarChartRounded,
  VolumeUp,
  NaturePeople,
  DashboardRounded,
  WorkOutlineRounded,
  CloudDownloadRounded,
  SpeakerRounded,
  SpeakerNotesOffRounded,
  PagesSharp,
} from "@material-ui/icons";
import { URL } from "./urls";

export const ROUTES = Object.freeze({
  "site-admin": [
    {
      id: 0,
      title: "Clients",
      icon: <PeopleAltRounded />,
      path: "clients",
    },
    {
      id: 1,
      title: "Affiliates",
      icon: <NaturePeople />,
      path: "view-affiliates/",
    },
    {
      id: 2,
      title: "Question Bank",
      icon: <AccountBalance />,
      path: "question-bank/",
    },
    {
      id: 3,
      title: "Billings",
      icon: <AccountBalanceWalletRounded />,
      path: "site-admin-billings/",
    },
    {
      id: 4,
      title: "Feedback",
      icon: <ForumRounded />,
      path: "feedback",
    },
    {
      id: 5,
      title: "Manage Users",
      icon: <PeopleAltRounded />,
      path: "manage-users",
    },
    {
      id: 6,
      title: "Newsletter",
      icon: <VolumeUp />,
      path: "newsletter/",
    },
    {
      id: 7,
      title: "Settings",
      icon: <SettingsApplicationsRounded />,
      path: "settings",
    },
  ],
  "client-admin": [
    {
      id: 0,
      title: "Dashboard",
      icon: <DashboardRounded />,
      path: URL.Dashboard,
    },
    {
      id: 1,
      title: "Jobs",
      icon: <AccountBoxRounded />,
      path: "campaigns",
    },
    {
      id: 3,
      title: "Candidates",
      icon: <PeopleAltRounded />,
      path: "candidates",
    },
    {
      id: 4,
      title: "Question Bank",
      icon: <AccountBalance />,
      path: "question-bank/",
    },
    /*{
      id: 5,
      title: "Exams",
      icon: <AssessmentRounded />,
      path: "exams",
    },*/
    {
      id: 55,
      title: "Exams",
      icon: <AssessmentRounded />,
      path: "exams-new",
    },
    {
      id: 7,
      title: "Analytics",
      icon: <BarChartRounded />,
      path: "analytics",
    },
    {
      id: 8,
      title: "Billings",
      icon: <AccountBalanceWalletRounded />,
      path: "billings",
    },
    // {
    //   id: 9,
    //   title: "Downloads",
    //   icon: <CloudDownloadRounded />,
    //   path: "downloads",
    // },
    {
      id: 10,
      title: "Report",
      icon: <PagesSharp />,
      path: "report",
    },
    {
      id: 11,
      title: "Settings",
      icon: <SettingsApplicationsRounded />,
      path: "settings",
    },
  ],
  facilitator: [
    {
      id: 0,
      title: "Candidates",
      icon: <PeopleAltRounded />,
      path: "candidates",
    },
  ],
  "talent-manager": [
    {
      id: 0,
      title: "Campaigns",
      icon: <AccountBalanceWalletRounded />,
      path: "campaigns",
    },
    {
      id: 1,
      title: "Candidates",
      icon: <PeopleAltRounded />,
      path: "candidates",
    },
    {
      id: 2,
      title: "Assessments",
      icon: <AssessmentRounded />,
      path: "assessments",
    },
  ],
});
