import React, { useEffect, useContext } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import MonitorSignIn from "./MonitorSignIn";
import ExamMonitor from "./ExamMonitor";
import { UserContext } from "../../context/UserContext";

const ExamMonitorRoutes = () => {
  const { value } = useContext(UserContext);
  const navigate = useNavigate();
  const userToken = sessionStorage.getItem("userToken");

  // console.log({ value });

  useEffect(() => {
    if (!userToken) {
      navigate("/exam-monitor/sign-in");
    }
  }, [value]);

  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Navigate to={`/exam-monitor/client`} />} />
        <Route path="/sign-in" element={<MonitorSignIn />} />
        <Route path="/client" element={<ExamMonitor user={value?.user} />} />
      </Routes>
    </React.Fragment>
  );
};

export default ExamMonitorRoutes;
