import createApiClient from "./api";
import fileDownload from "./export";

const client = createApiClient();

const download = fileDownload();

export const api = {
  getExams: ({
    perPage,
    dropdown,
    search,
    currentPage,
    campaignID,
    pipelineID,
    type,
  }) =>
    client
      .get(
        `v2/admin/exams?dropdown=${dropdown}&per_page=${perPage}&search=${search}&page=${currentPage}&campaign_ids=${campaignID}&pipeline_ids=${pipelineID}&type=${type}`
      )
      .then(({ data }) => data),  

    getExamsv2: ({ perPage, dropdown, search, currentPage, clientId, type, }) =>
    client
      .get(
        `/v2/admin/clients/${clientId}/exams?dropdown=${dropdown}&per_page=${perPage}&search=${search}&page=${currentPage}&type=${type}`
      )
    .then(({ data }) => data),

  updateExamsv2: ({payload, id}) =>
      client
        .put(
          `/v2/admin/clients/${payload?.client_id}/exams/${id}`, payload
        )
      .then(({ data }) => data),

  getExamsDropdown: () =>
    client.get(`v2/admin/exams?dropdown=1`).then(({ data }) => data),

  getExam: (id) => client.get(`v2/admin/exams/${id}`).then(({ data }) => data),

  updateVenueCode: ({ dateId, outletId }) =>
    client
      .patch(
        `v2/admin/exams/bookings/dates/${dateId}/outlets/${outletId}/update-venue-code`
      )
      .then(({ data }) => data),

  getCandidates: ({ id, search, perPage, currentPage }) =>
    client
      .get(
        `/v2/admin/exams/${id}/candidates?per_page=${perPage}&search=${search}&page=${currentPage}`
      )
      .then(({ data }) => data),

  getCandidateResult: ({ id, user_group_id }) =>
    client
      .get(`v2/admin/exams/${id}/candidates/${user_group_id}/results`)
      .then(({ data }) => data),

  getResult: ({ id, user_group_id, result_id }) =>
    client
      .get(
        `v2/admin/exams/${id}/candidates/${user_group_id}/results/${result_id}`
      )
      .then(({ data }) => data),

  getAttendance: ({
    perPage,
    dropdown,
    search,
    currentPage,
    campaignID,
    pipelineID,
    type,
  }) =>
    client
      .get(
        `v2/admin/exams/attendances/register?dropdown=${dropdown}&per_page=${perPage}&search=${search}&page=${currentPage}&campaign_ids=${campaignID}&pipeline_ids=${pipelineID}&type=${type}`
      )
      .then(({ data }) => data),

  getBookings: ({ perPage, search, currentPage }) =>
    client
      .get(
        `v2/admin/exams/bookings/candidates?per_page=${perPage}&search=${search}&page=${currentPage}`
      )
      .then(({ data }) => data),

  unlockOutlet: ({ dateId, outletId, payload }) =>
    client
      .patch(
        `v2/admin/exams/bookings/dates/${dateId}/outlets/${outletId}`,
        payload
      )
      .then(({ data }) => data),

  unlockExamV2: ({client_id, exam_id, user_exam_id, id}) =>
    client
      .post(
        `/v2/admin/clients/${client_id}/exams/${exam_id}/candidate/${id}/unlock`,
        {user_exam_id}
      )
      .then(({ data }) => data),

   resetExamV2: ({client_id, exam_id, user_exam_id, id}) =>
    client
      .post(
        `/v2/admin/clients/${client_id}/exams/${exam_id}/candidates/${id}/reset-exam/${user_exam_id}`,
        {user_exam_id}
      )
      .then(({ data }) => data),

  getExamBookings: ({ perPage, search, currentPage, examId }) =>
    client
      .get(
        `v2/admin/exams/bookings/view-outlets/${examId}?per_page=${perPage}&search=${search}&page=${currentPage}`
      )
      .then(({ data }) => data), 

  getExamCandidatesv2: ({ perPage, search, currentPage, examId, clientId }) =>
   client
    .get(
      `/v2/admin/clients/${clientId}/exams/${examId}/candidates?per_page=${perPage}&search=${search}&page=${currentPage}`
    )
  .then(({ data }) => data),

  getBookedCandidates: ({
    perPage,
    search,
    currentPage,
    examId,
    outletId,
    dateId,
  }) =>
    client
      .get(
        `v2/admin/exams/bookings/${examId}/outlets/${outletId}/dates/${dateId}/candidates?per_page=${perPage}&search=${search}&page=${currentPage}`
      )
      .then(({ data }) => data),

    getExamBookingsV2: ({
        perPage,
        search,
        currentPage,
        examId,
        clientId
      }) =>
        client
          .get(
            `v2/admin/clients/${clientId}/exams/${examId}/bookings?per_page=${perPage}&search=${search}&page=${currentPage}`
          )
          .then(({ data }) => data),

  updateExamBooking: ({ date_id, outlet_id, payload }) =>
    client
      .put(
        `v2/admin/exams/bookings/dates/${date_id}/outlets/${outlet_id}`,
        payload
      )
      .then(({ data }) => data),

  createExam: (payload) =>
    client.post(`v2/admin/exams`, payload).then(({ data }) => data),

  createExamV2: (payload) =>
    client.post(`/v2/admin/clients/${payload?.client_id}/exams`, payload)
   .then(({ data }) => data),

  updateExam: ({ id, payload }) =>
    client.put(`v2/admin/exams/${id}`, payload).then(({ data }) => data),

  deleteExam: (id) =>
    client.delete(`/v2/admin/exams/${id}`).then(({ data }) => data),

  toggleExam: ({ examId, payload }) =>
    client
      .post(`v2/admin/exams/${examId}/toggle-exam-lock`, payload)
      .then(({ data }) => data),

  getPipelines: () =>
    client.get(`v2/admin/pipelines?dropdown=1`).then(({ data }) => data),

  getAssessments: () =>
    client.get(`v2/admin/assessments?dropdown=1`).then(({ data }) => data),

  getCampaigns: () =>
    client.get(`v2/admin/campaigns?dropdown=1`).then(({ data }) => data),

  getOutlets: (id) =>
    client
      .get(`v2/admin/clients/${id}/outlets?dropdown=1&per_page=200`)
      .then(({ data }) => data),

  getBookingInformationv2: ({clientId, examId, bookingId}) =>
    client
      .get(`/v2/admin/clients/${clientId}/exams/${examId}/bookings/${bookingId}/view-bookings`)
      .then(({ data }) => data),

  createBooking: (payload) =>
    client.post(`v2/admin/exams/bookings`, payload).then(({ data }) => data),

  createBookingv2: ({ payload, client_id, exam_id }) =>
    client.post(`v2/admin/clients/${client_id}/exams/${exam_id}/bookings/`, payload)
   .then(({ data }) => data),

  resetExam: (payload) =>
    client
      .post(`v2/admin/exams/reset-progress`, { ...payload })
      .then(({ data }) => data),

  getDates: (campaignID) =>
    client.get(`v2/admin/bookings/dates/${campaignID}`).then(({ data }) => data),

  unlockExam: ({ id, payload }) =>
    client.post(`v2/admin/exams/unlock/${id}`, payload).then(({ data }) => data),

  bookVenue: (payload) =>
    client
      .post(`v2/admin/exams/bookings/book-a-candidate`, payload)
      .then(({ data }) => data),

  bookVenueV2: ({client_id, exam_id, user_id, payload}) =>
    client
      .post(`/v2/admin/clients/${client_id}/exams/${exam_id}/candidate/${user_id}/bookings`, payload)
      .then(({ data }) => data),
      
  getCandidateResponse: ({ examId, candidateId, assessmentId }) =>
    client
      .get(
        `v2/admin/exams/${examId}/candidates/${candidateId}/assessments/${assessmentId}/spool`
      )
      .then(({ data }) => data),

  downloadCandidateResponse: ({ examId, candidateId, assessmentId }) =>
    download
      .get(
        `v2/admin/export-data/question-response?exam_id=${examId}&assessment_id=${assessmentId}&user_group_id=${candidateId}`
      )
      .then(({ data }) => data),

  changeExamGroup: (payload) =>
    client
      .post(`v2/admin/candidates/update-exam-placement`, payload)
      .then(({ data }) => data),

  getSections: () => client.get(`v2/admin/sections?per_page=10000`).then(({ data }) => data),

  getExamsFromSpeedExams: () => client.get(`/v2/admin/speed-exam/exams`).then(({ data }) => data),

  getGroupsFromSpeedExams: () => client.get(`v2/admin/speed-exam/groups`).then(({ data }) => data),

};
