import React from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage = ({ url }) => {
  const navigate = useNavigate();

  return (
    <div className="container z-10 flex items-center justify-center gap-3 flex-col h-screen px-6 pt-32 mx-auto md:pt-0">
      <h1 className="mt-12 font-sans text-5xl font-light text-center text-gray-700 lg:text-left lg:text-8xl md:mt-0">
        Sorry, this page isn&#x27;t available
      </h1>
      {/* <div class="relative block w-full max-w-md mx-auto md:mt-0 lg:max-w-2xl">
                      <img src={LostHere} />
                    </div> */}
      <button
        className="my-10 border border-[#88a848] py-3 px-5 w-[200px] rounded-lg text-[#88a848] text-xl font-medium"
        onClick={() => navigate(url || -1)}
      >
        Go back home
      </button>
    </div>
  );
};

export default NotFoundPage;
