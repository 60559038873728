import React from "react";

const Filter = ({
  isBody,
  options,
  optionValueKey,
  optionTextKey,
  setFilter,
  placeholder,
  hasDefault,
}) => {
  const handleFilter = (e) => {
    if (isBody) {
      return setFilter(JSON.parse(e.target.value));
    }
    setFilter(e.target.value);
  };

  return (
    <select
      className="border border-slate-300 text-sm rounded-md py-[7.5px] duration-200 focus:border-customGreen focus:ring-0 outline-none"
      style={{ width: "150px" }}
      onChange={handleFilter}
    >
      {placeholder && (
        <option selected disabled>
          {placeholder}
        </option>
      )}
      {hasDefault !== false && <option value={""}>All</option>}
      {options &&
        options?.length &&
        options?.map((option, index) => (
          <option
            key={index}
            value={isBody ? JSON.stringify(option) : option[optionValueKey]}
          >
            {option[optionTextKey]}
          </option>
        ))}
    </select>
  );
};

export default Filter;
