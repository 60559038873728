import React, { useState, useEffect, useRef } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const VerticalMenu = ({ children, isBackground, width, customIcon, top }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef();

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isMenuOpen]);

  const handleToggle = () => {};
  return (
    <div className="relative" ref={menuRef}>
      {  customIcon ?
          <span onClick={() => setIsMenuOpen(!isMenuOpen)}>{customIcon}</span>
      : <MoreVertIcon
        className={`cursor-pointer ${isBackground ? "text-customBlue" : ""}`}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      />}
      {isMenuOpen && (
        <div
          style={{ right: "0", top: top || "23px", zIndex: "10" }}
          className={`absolute ${
            width ? width : "min-w-[130px]"
          } mt-1 h-auto ease-in-out transition-all overflow-hidden origin-top-right bg-white rounded-md shadow-lg dark:bg-gray-800 ring-1 ring-black ring-opacity-5`}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default VerticalMenu;
