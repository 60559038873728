import React, {Suspense, lazy } from 'react'
import { Navigate, Route, Routes as AffiliateRoutes } from "react-router-dom";
import { URL } from '../../../urls';
import Fallback from '../../admin/components/FallBack/Fallback';
import AdminHeader from '../../admin/components/AdminHeader/AdminHeader';
import ViewAffiliateLinks from './ViewsAffiliateLinks/ViewAffiliateLinks';
//pages
const Dashboard = lazy(() => import("./dashboard/Dashboard"));

const AffiliateRoutes_ = () => {
    
  return (
    <Suspense 
       fallback={
         <div className="bg-white w-full" style={{  height: "h-[100vh]" }}> 
            <Fallback />
          </div>
       }
    >
        <AdminHeader />
        <AffiliateRoutes>
                  <Route 
                         path="/" 
                         element={ <Navigate to={`/affiliate/${URL.affiliate_dashboard}`} /> } 
                   />  
                   <Route path={`/${URL.affiliate_dashboard}`}  element={ <Dashboard /> } />  
                   <Route path={`/${URL.affiliate_campaign_links}`}  element={ <ViewAffiliateLinks /> } /> 
        </AffiliateRoutes>
    </Suspense>
  )
}

export default AffiliateRoutes_