import React, { useState, useRef, useEffect } from "react";
import RecentActivities from "./components/RecentActivities";
import io from "socket.io-client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToolbox, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { api } from "../../api";
import { useQuery } from "react-query";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import ResetModal from "./components/ResetModal";
import Timer from "./components/Timer";
import { Link } from "react-router-dom";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

function convertDate(isoDate) {
  if (!isoDate) return null;
  let timestamp = Date.parse(isoDate);
  let jsDate = new Date(timestamp);
  let fDate = new Intl.DateTimeFormat("en-uk", {
    dateStyle: "long",
    timeStyle: "short",
  });
  return fDate.format(jsDate);
}

const ExamMonitor = ({ user }) => {
  const options = [
    {
      name: "Today",
      value: "today",
    },
    {
      name: "Last 7 Days",
      value: "last_7_days",
    },
    {
      name: "Last 30 Days",
      value: "last_30_days",
    },
  ];
  const [activeValue, setActiveValue] = useState("today");
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [resultsDisplayLimit, setResultsDisplayLimit] = useState(6);
  const [showModal, setShowModal] = useState(false);

  const increaseDisplayLimit = () => {
    setResultsDisplayLimit(resultsDisplayLimit + 4);
  };

  const { isLoading, data, refetch, isError, error } = useQuery(
    ["get-exam-monitor-summary", activeValue],
    async () => {
      const data = await api.getExamMonitorSummary("client", activeValue);
      return data;
    },
    {
      refetchOnWindowFocus: true,
    }
  );

  // console.log({ data });

  const { data: examMonitor, refetch: refetchExam } = useQuery(
    ["get-exam-monitor", activeValue, search, filter],
    async () => {
      const data = await api.getExamMonitor(
        "client",
        activeValue,
        search,
        filter
      );
      return data;
    },
    {
      refetchOnWindowFocus: true,
    }
  );

  // console.log({ examMonitor });

  const refetchData = () => {
    refetch();
    refetchExam();
  };

  const { data: assessmentData } = useQuery(
    ["assessments"],
    async () => {
      const data = await api.fetchAllAssessments(user?.client_id);
      return data.data;
    },
    {
      cacheTime: 30000,
      staleTime: 30000,
    }
  );

  const handleClick = async (option) => {
    setActiveValue(option.value);
    await refetch();
    await refetchExam();
  };

  const Icon = ({ number }) => (
    <div className="flex items-center justify-center">
      <p>{number}</p>
    </div>
  );

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleFilter = (value) => {
    setFilter(value);
  };

  // console.log({ filter });

  return (
    <React.Fragment>
      {showModal && (
        <ResetModal
          assessments={assessmentData.assessments}
          setShowModal={setShowModal}
        />
      )}
      <div>
        <div className="p-4 bg-primary text-white mb-10 flex items-center justify-between">
          <Link
            className="rounded px-3 py-2 flex items-center justify-center gap-1 bg-white text-customGreen"
            to="/admin"
            style={{ boxShadow: "2px 2px 0 0 black" }}
          >
            <FontAwesomeIcon className="text-xs" icon={faArrowLeftLong} />
            <p className="text-xs">Back to Dashboard</p>
          </Link>
          <p className="font-medium text-lg">EXAM MONITOR</p>
          {/* <span>jul 19 2023 01:33:00 pm</span> */}
        </div>
        <div className="w-full lg:hidden flex flex-col p-10">
          <div>
            <div className="flex mb-6 space-x-4 text-sm">
              {options.map((option, index) => (
                <button
                  onClick={() => {
                    handleClick(option);
                  }}
                  className={`px-4 py-2 rounded-md ${
                    activeValue === option.value
                      ? "bg-primary text-white"
                      : "bg-gray-200"
                  }`}
                  key={index}
                >
                  {option.name}
                </button>
              ))}
            </div>
          </div>
          <div className="mb-6 max-w-fit">
            <div className="flex items-center border-2 rounded-md shadow-xl">
              <button
                className={`flex-1 flex items-center justify-center flex-col gap-1 py-6 ${
                  filter === "" ? "bg-green-100" : null
                } hover:bg-green-200`}
                onClick={() => handleFilter("")}
              >
                <div>{data?.data?.total}</div>
                <span className="text-sm">All Attempts</span>
              </button>
              <button
                className={`flex-1 flex items-center justify-center flex-col gap-1 py-6 ${
                  filter === "started" ? "bg-green-100" : null
                } hover:bg-green-200`}
                onClick={() => handleFilter("started")}
              >
                <div>{data?.data?.started}</div>
                <div className="flex items-center justify-center gap-1">
                  <p className="text-sm">Taking</p>
                  <div className="h-2 w-2 rounded-full bg-orange-400"></div>
                </div>
              </button>
              <button
                className={`flex-1 flex items-center justify-center flex-col gap-1 py-6 ${
                  filter === "completed" ? "bg-green-100" : null
                } hover:bg-green-200`}
                onClick={() => handleFilter("completed")}
              >
                <div>{data?.data?.completed}</div>
                <div className="flex items-center justify-center gap-1">
                  <p className="text-sm">Finished</p>
                  <div className="h-2 w-2 rounded-full bg-green-500"></div>
                </div>
              </button>
              <button
                className={`flex-1 flex items-center justify-center flex-col gap-1 py-6 ${
                  filter === "terminated" ? "bg-green-100" : null
                } hover:bg-green-200`}
                onClick={() => handleFilter("terminated")}
              >
                <div>{data?.data?.terminated}</div>
                <div className="flex items-center justify-center gap-1">
                  <p className="text-sm">Terminated</p>
                  <div className="h-2 w-2 rounded-full bg-red-500"></div>
                </div>
              </button>
            </div>
          </div>
          <RecentActivities currentPeriod={activeValue} />
          <div className="flex items-center justify-center gap-1">
            <p>Data will refresh in</p>
            <Timer refetch={refetchData} />
            <p>seconds.</p>
          </div>
        </div>
        <div className="w-full  lg:flex lg:gap-x-2 md:p-10">
          <div className="w-full lg:w-3/5">
            <div className="flex justify-center">
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(1,1fr)",
                  gap: "20px",
                  border: "1px solid #ccc",
                  maxHeight: "633px",
                  overflowY: "scroll",
                  padding: "10px",
                  width: "100%",
                }}
              >
                <div className="w-full flex items-center justify-start">
                  <input
                    type="search"
                    placeholder="Search..."
                    className="text-xs border border-slate-300 rounded px-4 py-2 flex-1 focus:ring-0 focus:border-customOrange duration-200"
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
                <VerticalTimeline lineColor="#ccc" layout={"1-column-left"}>
                  {examMonitor?.data
                    .slice(0, resultsDisplayLimit)
                    .map((exam, index) => (
                      <VerticalTimelineElement
                        key={index}
                        className="vertical-timeline-element--work"
                        contentStyle={{
                          background: "#fff",
                          color: "black",
                          boxShadow: "3px 3px 0 0 #36454f",
                          border: "1px solid #36454f",
                        }}
                        contentArrowStyle={{
                          borderRight: "7px solid #36454f",
                        }}
                        date={convertDate(exam.time)}
                        iconStyle={{
                          background: "#36454f",
                          color: "#fff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        icon={<Icon number={index + 1} />}
                      >
                        <h3 className="vertical-timeline-element-title">
                          {exam.user_name}
                        </h3>
                        <h4 className="vertical-timeline-element-subtitle">
                          {exam.group_title}
                        </h4>
                        <p>{exam.progress}</p>
                        <p>{exam.status}</p>
                      </VerticalTimelineElement>
                      // <div
                      //   key={index}
                      //   style={{ boxShadow: "3px 3px 0 0 #36454f" }}
                      //   className="p-3 bg-white rounded-md border border-slate-300"
                      // >
                      //   <div className="overflow-hidden">
                      //     <div className="mb-4 flex items-center gap-3 justify-between text-xl font-medium text-gray-800 ">
                      //       <p>{exam.user_name}</p>
                      //       <p className="font-normal">
                      //         {convertDate(exam.time)}
                      //       </p>
                      //     </div>
                      //     <div className=" text-sm font-light leading-loose text-gray-700 ">
                      //       {/* <div className="font-bold">userid: {exam.userId}</div> */}
                      //       <div>
                      //         Group Title :{" "}
                      //         <span className="font-medium text-base">
                      //           {exam.group_title}
                      //         </span>
                      //       </div>
                      //       <div>
                      //         Progress :{" "}
                      //         <span className="font-medium text-base">
                      //           {exam.progress}
                      //         </span>
                      //       </div>
                      //       <div>
                      //         Status :{" "}
                      //         <span className="font-medium text-base">
                      //           {exam.status}
                      //         </span>
                      //       </div>
                      //       {/* <div className="w-full flex items-center justify-between mt-5">
                      //         <button
                      //           className="text-baseFont py-1 rounded-md bg-customGreen text-white px-3"
                      //           onClick={() => {
                      //             handleReset(exam.id);
                      //           }}
                      //         >
                      //           Reset
                      //         </button>
                      //         <button
                      //           className="text-baseFont py-1 rounded-md bg-slate-500 text-white px-3"
                      //           onClick={() => {
                      //             handleTerminate(exam.id);
                      //           }}
                      //         >
                      //           Terminate
                      //         </button>
                      //       </div> */}
                      //     </div>
                      //     {/* <div cclassName="mb-2 text-2xl font-bold text-gray-500 ">
                      //     <span>groupdid: {exam.groupId}</span>
                      //   </div> */}
                      //   </div>
                      // </div>
                    ))}
                </VerticalTimeline>
              </div>
            </div>

            <div className="flex  justify-center">
              {resultsDisplayLimit < examMonitor?.data?.length && (
                <div className=" w-46 mt-10 bg-primary rounded-md">
                  <button
                    className="text-white p-4 text-baseFont font-medium flex justify-end"
                    onClick={increaseDisplayLimit}
                  >
                    Load more...
                  </button>{" "}
                </div>
              )}
            </div>
            {examMonitor?.data?.length === 0 && (
              <div className="grid place-items-center h-full">
                <div className="flex flex-col justify-center">
                  <FontAwesomeIcon icon={faToolbox} className="text-6xl" />
                  <p className="pt-6 text-center font-semibold text-xl">
                    No one is taking exam right now!
                  </p>
                  <p className="pt-6 font-medium text-base">
                    We will display detail when someone starts the exam.
                  </p>
                </div>
                {/* )} */}
              </div>
            )}
          </div>
          <div className="w-full hidden lg:w-2/5 lg:flex flex-col">
            <div>
              <div className="flex  mb-6 space-x-4 text-sm">
                {options.map((option, index) => (
                  <button
                    onClick={() => {
                      handleClick(option);
                    }}
                    className={`px-4 py-2 rounded-md ${
                      activeValue === option.value
                        ? "bg-primary text-white"
                        : "bg-gray-200"
                    }`}
                    key={index}
                  >
                    {option.name}
                  </button>
                ))}
              </div>
            </div>
            <div className="mb-6 w-full">
              <div className="flex items-center border-2 rounded-md shadow-xl">
                <button
                  className={`flex-1 flex items-center justify-center flex-col gap-1 py-6 ${
                    filter === "" ? "bg-green-100" : null
                  } hover:bg-green-200`}
                  onClick={() => handleFilter("")}
                >
                  <div>{data?.data?.total}</div>
                  <span className="text-sm">All Attempts</span>
                </button>
                <button
                  className={`flex-1 flex items-center justify-center flex-col gap-1 py-6 ${
                    filter === "started" ? "bg-green-100" : null
                  } hover:bg-green-200`}
                  onClick={() => handleFilter("started")}
                >
                  <div>{data?.data?.started}</div>
                  <div className="flex items-center justify-center gap-1">
                    <p className="text-sm">Taking</p>
                    <div className="h-2 w-2 rounded-full bg-orange-400"></div>
                  </div>
                </button>
                <button
                  className={`flex-1 flex items-center justify-center flex-col gap-1 py-6 ${
                    filter === "completed" ? "bg-green-100" : null
                  } hover:bg-green-200`}
                  onClick={() => handleFilter("completed")}
                >
                  <div>{data?.data?.completed}</div>
                  <div className="flex items-center justify-center gap-1">
                    <p className="text-sm">Finished</p>
                    <div className="h-2 w-2 rounded-full bg-green-500"></div>
                  </div>
                </button>
                <button
                  className={`flex-1 flex items-center justify-center flex-col gap-1 py-6 ${
                    filter === "terminated" ? "bg-green-100" : null
                  } hover:bg-green-200`}
                  onClick={() => handleFilter("terminated")}
                >
                  <div>{data?.data?.terminated}</div>
                  <div className="flex items-center justify-center gap-1">
                    <p className="text-sm">Terminated</p>
                    <div className="h-2 w-2 rounded-full bg-red-500"></div>
                  </div>
                </button>
              </div>
            </div>
            <RecentActivities currentPeriod={activeValue} />
            <div className="flex items-center justify-center gap-1">
              <p>Data will refresh in</p>
              <Timer refetch={refetchData} />
              <p>seconds.</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExamMonitor;
