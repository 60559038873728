import React, {useState} from 'react'
import logo from "../../assets/testAssessify_small.png";
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { validate } from '../../utils/signupValidation';
import { useQuery, useMutation } from 'react-query';
import { api } from '../../api/auth';
import { api as indexApi } from '../../api';
import toast from 'react-hot-toast';
import CenteredModal from '../admin/components/Modal/CenteredModal';
import OtpForm from './components/OtpForm';


const SignUp = () => {

  const [showPassword, setShowPassword ] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [ companyLogo, setCompanyLogo ] = useState([])
  const [ errors, setErrors ] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [ success, setSuccess ] = useState(false)
  const [logoPreview, setLogoPreview] = useState('')

  const { data: countries, isLoading, } = useQuery("get countries", api.getCountries  );

  const signUpMutation = useMutation(indexApi.createClient, {
    onSuccess: data => {         
        toast.remove()
        toast.success(data?.message)
        setSuccess(true)
        setModalOpen(true)
    },
    onMutate: () => {
       toast.loading('Loading...')
    },
    onError: (error) => {
      toast.remove() 
      toast.error(error.response.data.message)        
    }
  })

  const initialValues = {
    first_name: "",
    last_name: "",
    client_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    og_name:"",
    state: "",
    country: "",
    phone_number: "",
    logo: "",     
  }
 
  const toggleViewPassword = () => setShowPassword(!showPassword)
  const toggleViewConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword)

  const uploadClientLogo = e => {              
    const file = e.target.files[0];                            
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
         setLogoPreview(reader.result)
      };
      reader.readAsDataURL(file);
    } 
    else setLogoPreview(null)     
     setCompanyLogo(file)                             
  }

  const onSubmit = (values, { setSubmitting }) => {         

    const allowedFileTypes = ["image/jpeg", "image/png", "image/gif"];     
    if (!companyLogo?.name) {
       setErrors('Organisation logo is required.')
     }
     else if(companyLogo?.type && !allowedFileTypes.includes(companyLogo?.type)) {
        setErrors('Only JPEG, PNG, and GIF files are allowed.')      
     }
     else{       
        try {
          const signupData = new FormData();    
          signupData.append('first_name', values.first_name);
          signupData.append('last_name', values.last_name);
          signupData.append('client_name', values.og_name);
          signupData.append('email', values.email);
          signupData.append('password', values.password);
          signupData.append('phone_number', values.phone_number);
          signupData.append('logo_image', companyLogo);
          signupData.append('password_confirmation', values.password_confirmation);              
          signUpMutation.mutate(signupData)            
          setErrors('');
        } catch (error) {
          console.error('Error signing up:', error);
          // Handle error if needed
        }         
       sessionStorage.setItem('verification-email', values.email)
     }
   setSubmitting(false); 
}
  
  return (
    <div>   
        <div className="w-full flex items-center justify-between mt-3 shadow-lg py-3">
           <img src={logo} alt="logo" className="h-[40px] w-[180px]" />
          <Link to={'/'} className='bg-customOrange text-white mr-5 py-3 px-16 rounded-lg text-md'>
              Login
          </Link>
        </div>
        <section className='overflow-auto h-[calc(100vh-40px)] pb-8'>
           <h5 className='text-2xl pl-3 md:pl-5 mt-8'> Welcome To Testassessify </h5>            
           <CenteredModal
              width={"w-[500px]"}
              title={<span className="text-xl"> OTP Verification </span>}
              open={modalOpen}
              setOpen={setModalOpen}
            >
             <OtpForm />
        </CenteredModal>
            <section
                style={{ backgroundSize:'100% 100%'}}
                className='rounded-[25px] shadow sign-up-container p-5 md:p-8  mx-auto bg-no-repeat  w-full md:w-[80%] bg-gray-100 mt-5'>
             <div className='w-full md:w-[70%] xl:w-[50%] md:ml-5 bg-white rounded-[25px] p-5 md:p-8'>
                 <h3 className='text-xl pt-3 text-center mb-8'>
                     Register Your Organization to Get Started.
                 </h3>
                 <Formik
                    initialValues={initialValues}
                    validate={validate}
                    onSubmit={onSubmit}
                  >
                    {({ isSubmitting, values, isValid, setFieldValue }) => (
                        <Form> 
                           { logoPreview ?
                               <img src={logoPreview} alt="profile" className='w-[90px] h-[90px]' />
                             :
                              <div className='border border-2  border-dotted rounded-lg w-[90px] h-[90px] flex items-center justify-center'>
                                <img src="/user.jpg" alt="profile" className='w-[70px] h-[70px]' />
                             </div>
                            } 
                            <label htmlFor="logo" className='text-sm text-customGreen'>
                             </label>
                             <Field
                                  type="file" id="logo"
                                  className="p-0 mt-3 m-0 text-white"  
                                  name="logo" 
                                  accept="image/*" 
                                  onChange={ (event) => {
                                     uploadClientLogo(event)                                     
                                    }}                               
                              />
                              <p className='text-md text-red-500 py-2'>
                                 {errors}
                              </p>                               
                          <div class="relative mt-5 rounded mb-4 flex flex-wrap items-stretch">
                              <span
                                class="flex items-center bi bi-person whitespace-nowrap rounded-l border border-r-0 border-solid border-neutral-300 px-5 py-4 text-center text-lg font-normal leading-[1.6] text-neutral-700"
                                id="basic-addon1"
                              ></span>
                              <Field
                                  type="text"
                                  class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-customGreen focus:text-neutral-700 focus:outline-none"
                                  placeholder="First Name.."
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  name='first_name'
                              />
                          </div>
                          <ErrorMessage 
                            name="first_name" 
                            className='text-md text-red-500 pb-3'
                            component="p" 
                          />
                          <div class="relative rounded mb-4 flex flex-wrap items-stretch">
                              <span
                                class="flex items-center bi bi-person whitespace-nowrap rounded-l border border-r-0 border-solid border-neutral-300 px-5 py-4 text-center text-lg font-normal leading-[1.6] text-neutral-700"
                                id="basic-addon1"
                              ></span>
                              <Field
                                  type="text"
                                  class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-customGreen focus:text-neutral-700 focus:outline-none"
                                  placeholder="Last Name.."
                                  aria-label="Username"
                                  name='last_name'
                                  aria-describedby="basic-addon1"
                              />
                          </div>
                          <ErrorMessage 
                            name="last_name" 
                            className='text-md text-red-500 pb-3'
                            component="p" 
                          />
                          <div class="relative rounded mb-4 flex flex-wrap items-stretch">
                              <span
                                class="flex items-center bi bi-building whitespace-nowrap rounded-l border border-r-0 border-solid border-neutral-300 px-5 py-4 text-center text-lg font-normal leading-[1.6] text-neutral-700"
                                id="basic-addon1"
                              ></span>
                              <Field
                                  type="text"
                                  name='og_name'
                                  class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-customGreen focus:text-neutral-700 focus:outline-none"
                                  placeholder="Organisation Name.."
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                              />
                          </div>
                          <ErrorMessage 
                            name="og_name" 
                            className='text-md text-red-500 pb-3'
                            component="p" 
                          />
                          <div class="relative rounded mb-4 flex flex-wrap items-stretch">
                              <span
                                class="flex items-center bi bi-phone whitespace-nowrap rounded-l border border-r-0 border-solid border-neutral-300 px-5 py-4 text-center text-lg font-normal leading-[1.6] text-neutral-700"
                                id="basic-addon1"
                              ></span>
                              <Field
                                  type="number"
                                  name='phone_number'
                                  class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-customGreen focus:text-neutral-700 focus:outline-none"
                                  placeholder="Contact Number.."
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"                                  
                              />
                          </div>
                          <ErrorMessage 
                            name="phone_number" 
                            className='text-md text-red-500 pb-3'
                            component="p" 
                          />
                          <div class="relative rounded mb-4 flex flex-wrap items-stretch">
                              <span
                                class="flex items-center bi bi-envelope whitespace-nowrap rounded-l border border-r-0 border-solid border-neutral-300 px-5 py-4 text-center text-lg font-normal leading-[1.6] text-neutral-700"
                                id="basic-addon1"
                              ></span>
                              <Field
                                  type="email"
                                  name='email'
                                  class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-customGreen focus:text-neutral-700 focus:outline-none"
                                  placeholder="Email Address.."
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                              />
                          </div>
                          <ErrorMessage 
                            name="email" 
                            className='text-md text-red-500 pb-3'
                            component="p" 
                          />
                          <section className='md:grid grid-cols-2 gap-4'>
                            <div class="relative rounded mb-4">                      
                                <Field
                                    type="text"
                                    as="select"
                                    class="relative m-0  block w-full min-w-0 flex-auto rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-customGreen focus:text-neutral-700 focus:outline-none"                                   
                                    aria-label="Username"
                                    name="country"
                                    aria-describedby="basic-addon1"
                                >
                                  <option value=""> Select Country.. </option>
                                   {!isLoading ? 
                                      countries?.data?.map( country => {
                                         return (
                                           <option key={country?.name} value={country?.name}>
                                                  {country?.name} 
                                            </option>
                                         )
                                      } )
                                   : 
                                    <option value=""> Getting Countries.. </option>
                                   }
                                </Field>
                                <ErrorMessage 
                                  name="country" 
                                  className='text-md text-red-500 pb-3'
                                  component="p" 
                              />
                            </div> 
                            <div class="relative rounded mb-4">                      
                                <Field
                                    type="text"
                                    as="select"
                                    class="relative m-0 block w-full min-w-0 flex-auto rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-customGreen focus:text-neutral-700 focus:outline-none"
                                    placeholder="State"
                                    aria-label="Username"
                                    name="state"
                                    aria-describedby="basic-addon1"
                                    disabled={values?.country ? false : true}
                                >
                                  <option value=""> Select State</option>
                                  {
                                    countries?.data?.find( ctr => ctr?.name === values?.country  )?.states?.length ?
                                    countries?.data?.find( ctr => ctr?.name === values?.country  )?.states?.map(
                                      state => {
                                         return(
                                             <option key={state?.name} value={state?.name}>
                                              {state?.name} 
                                            </option>
                                         )
                                      }
                                    )
                                    : <option value=""> No State Found</option>
                                  }
                                </Field>
                                <ErrorMessage 
                                  name="state" 
                                  className='text-md text-red-500 pb-3'
                                  component="p" 
                                />
                            </div>
                          </section>

                          <div class="relative rounded mb-4 flex flex-wrap items-stretch">
                              <span
                                class="flex items-center bi bi-lock whitespace-nowrap rounded-l border border-r-0 border-solid border-neutral-300 px-5 py-4 text-center text-lg font-normal leading-[1.6] text-neutral-700"
                                id="basic-addon1"
                              ></span>
                              <Field
                                  type={ showPassword ? "text" : "password"}                                                                          
                                  class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-r-0 border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-customGreen focus:text-neutral-700 focus:outline-none"
                                  placeholder="Password.."
                                  name="password"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                              />
                              <span
                                class="flex items-center bg-gray-100 hover:cursor-pointer bi bi-eye whitespace-nowrap rounded-l-0 border border-r border-solid border-neutral-300 px-5 py-4 text-center text-lg font-normal leading-[1.6] text-neutral-700"
                                id="basic-addon1"
                                onClick={toggleViewPassword}
                              ></span>
                          </div>
                          <ErrorMessage 
                            name="password" 
                            className='text-md text-red-500 pb-3'
                            component="p" 
                          />
                          <div class="relative rounded mb-4 flex flex-wrap items-stretch">
                              <span
                                class="flex items-center bi bi-lock whitespace-nowrap rounded-l border border-r-0 border-solid border-neutral-300 px-5 py-4 text-center text-lg font-normal leading-[1.6] text-neutral-700"
                                id="basic-addon1"
                              ></span>
                              <Field
                                  type={ showConfirmPassword ? "text" : "password"}
                                  name='password_confirmation'
                                  class="relative m-0 block w-[1px] min-w-0 flex-auto rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-customGreen focus:text-neutral-700 focus:outline-none"
                                  placeholder="Confirm Password.."
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                              />
                              <span
                                class="flex items-center hover:cursor-pointer bg-gray-100 bi bi-eye whitespace-nowrap rounded-l-0 border border-r border-solid border-neutral-300 px-5 py-4 text-center text-lg font-normal leading-[1.6] text-neutral-700"
                                id="basic-addon1"
                                onClick={toggleViewConfirmPassword}
                              ></span>
                          </div>
                          <ErrorMessage 
                            name="password_confirmation" 
                            className='text-md text-red-500 pb-3'
                            component="p" 
                          />
                          <div className='flex justify-end pt-3'>
                              { !success ?
                                <button disabled={isSubmitting || signUpMutation.isLoading} 
                                  type="submit" className='bg-customBlue text-white mr-5 py-3 px-16 rounded-lg text-md'> 
                                  Register
                               </button>
                              :
                              <button  onClick={ () => setModalOpen(true) }
                                type="button" className='bg-customBlue text-white mr-5 py-3 px-16 rounded-lg text-md'> 
                                 Enter Otp
                              </button>
                            }
                          </div>
                        </Form>
                    )}
                  </Formik>                   
             </div>
         </section>
        </section>                  
    </div>
  )
}

export default SignUp